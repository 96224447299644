import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { NavBar } from "./components/NavBar";
import { Banner } from "./components/Banner";
import { Skills } from "./components/Skills";
import { Projects } from "./components/Projects";
import { Footer } from "./components/Footer";
import { Helmet } from "react-helmet";

function App() {
  return (
    <>
      <Helmet>
        <title> Ori Marom's Portfolio </title>
        <meta
          name="description"
          content="Hi this is my most latest portfolio, checkout orimarom.com"
        />
        <link rel="icon" type="image/png" href="favicon.ico" sizes="30x30" />
      </Helmet>
      <div className="App">
        <NavBar />
        <Banner />
        <Skills />
        <Projects/>
        <Footer />
      </div>
    </>
  );
}

export default App;
