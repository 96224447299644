import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import { ProjectCard } from "./ProjectCard";
import projImg1 from "../assets/img/project-img1.png";
import projImg2 from "../assets/img/project-img2.png";
import projImg3 from "../assets/img/under-construction.png";
import colorSharp2 from "../assets/img/color-sharp2.png";
import "animate.css";
import TrackVisibility from "react-on-screen";

export const Projects = () => {
  const projects = [
    {
      title: "Quiz Game",
      description: "Quiz game built with React, Redux , external API",
      imgUrl: projImg1,
      Link: "https://monday-quiz-game-ori-marom.netlify.app"
    },
    {
      title: "Quizy Smart Dating",
      description: "A new kind of dating app, play trivia and the app will find the best romantic/friendly match for you based on your answers and personality",
      imgUrl: projImg2,
      Link: "https://quizy-smart-dating.herokuapp.com/"
    },
    {
      title: "Working on my next project",
      description: "",
      imgUrl: projImg3,
    },
  ];

  return (
    <section className="project" id="project">
      <Container>
        <Row>
          <Col size={12}>
            <TrackVisibility>
              {({ isVisible }) => (
                <div className={isVisible ? "animate__animated animate__fadeIn" : ""}>
                  <h2>Projects</h2>
                  <p></p>
                  {/* <Tab.Container id="projects-tabs" defaultActiveKey="first"> */}
                    <Tab.Content
                      id="slideInUp"
                      className={isVisible ? "animate__animated animate__slideInUp" : ""}
                    >
                      <Row>
                        {projects.map((project, index) => {
                          return (<><ProjectCard key={index} {...project} /></>);
                        })}
                      </Row>
                    </Tab.Content>
                  {/* </Tab.Container> */}
                </div>
              )}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
      <img className="background-image-right" src={colorSharp2}></img>
    </section>
  );
};
