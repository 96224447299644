import React from "react";
import styles from "./DownloadCv.module.scss";
import CVFile from "../../assets/CV/ori-marom-cv.pdf";

export const DownloadCv = () => {
  return (
      <a href={CVFile} download="ori-marom-cv.pdf">
    <div className={styles.button}>
      <span>
        Download my CV
      </span>
    </div>
      </a>
  );
};
